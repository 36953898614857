@import url('https://fonts.googleapis.com/css?family=Vidaloka');

.contact-photo-section {
    position: relative;
    width: 100%;
    height: 80vh;

    .contact-title-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}


.my-contact-form {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 30px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .contact-info {
        padding: 29px 60px 26px 58px;

        h2 {
            color: #10181d;
            font-family: "Merriweather", serif;
            font-size: 60px;
            line-height: 60px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0px;
            text-transform: none;
        }

        p {
            color: #0a0909;
            font-family: "Merriweather", serif;
            font-size: 16px;
            line-height: 25px;
            font-style: normal;
            font-weight: 300;
        }

        .contact-details {
            p {
                margin: 0;
                color: #0a0909;
                font-family: 'Arial', sans-serif;
                font-size: 16px;
                line-height: 25px;
                font-style: normal;
                font-weight: 300;
            }
        }
    }

    .contact-form {
        padding: 29px 60px 26px 58px;
        background-color: #ccc7a9a3;

        .form-form {
            display: flex;
            flex-direction: column;

            label {
                font-size: 16px;
                margin-bottom: 5px;
            }

            input,
            textarea {
                padding: 10px;
                margin-bottom: 15px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            button {
                width: 30%;
                padding: 10px;
                background: transparent;
                color: #000000;
                border: 1px solid black;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #c7b449a3;
                }
                @media (max-width: 760px) {
                    width:100%;
                }
            }
        }
    }
}
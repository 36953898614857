@import url("https://fonts.googleapis.com/css?family=Merriweather");

.video-info {
  background: rgba(33, 33, 33, 1);
  width: 100%;
  padding: 20px 90px;
}

@media (max-width: 767px) {
  .video-info {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  .videoPara {
    flex-direction: column;
    align-items: normal;
  }
}


.videoPara {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.videoPara a.videoBtn {
  cursor: pointer;
  text-decoration: none;
  background: #fcc122;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  padding: 18px 15px;
  margin: 10px;
  min-width: 220px;
  // margin-left: 10px;
  text-align: center;
}

.video-section {
  margin-top: 70px;
}
.parent-container {
  position: relative;
  margin-top: 500px;
  height: 100vh;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  object-fit: cover;
}

.Skip-to-Main-Content {
  position: absolute;
  margin-left: 10px;
  top: 12%;
  z-index: 1;

  @media (max-width: 767px) {
    position: absolute;
    left: 10;
    top: 30%;
    z-index: 1;
  }
}

.skip-main {
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 9px 6px;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
}

.skip-main:hover {
  border: 2px solid rgba(51, 51, 51, 1);
  background: #333;
}
@import url('https://fonts.googleapis.com/css?family=Merriweather');
.counter-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    margin-top: 5rem;
}

.counter {
    text-align: center;
}

.count {
    font-size: 30px;
    font-family: 'Merriweather', serif;
    font-weight: 500;
    line-height: 45px;
    padding: 0 5px;
}

.label {
    font-size: 15px;
    color: #666666;
    line-height: 20px;
    padding-top: 5px;
    text-align: left;
}

//Media queries for mobile
@media only screen and (max-width: 767px) {
    .counter-container {
        flex-direction: column;
        text-align: left;
        margin-top: 4rem;
        align-items: flex-start;
        margin-left: 8%;
    }

    .counter {
        margin-bottom: 1rem;
    }

    .count {
        font-size: 1.5em;
        margin-bottom: 0.3em;
        font-size: 35px;
        font-family: 'Merriweather', serif;
        font-weight: bolder;
        line-height: 45px;
        padding: 0 5px;
    }

    .label {
        font-size: 15px;
        color: #666666;
        line-height: 20px;
        padding-top: 5px;
        text-align: left;
    }
}
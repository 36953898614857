@import url("https://fonts.googleapis.com/css?family=Merriweather");

.Prospective-mainwrape {
  background: #f9f9f9;
}

.background-image-container {
  position: relative;
  overflow: hidden;
  min-height: 370px;

  .background-image {
    display: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .designthink-contsection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    max-width: 100%;
  }

  .arrow-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    border: 1px solid #fcc32b;
    text-decoration: none;
    color: black;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transform: translateY(100%);
      transition: transform 0.3s ease-out;
    }

    &:hover {
      // transform: translateY(-50%);
      opacity: 0.9;
      border-color: #f28d08;

      &:before {
        // transform: translateY(0);
      }
    }
  }

  @media (max-width: 768px) {
    .designthink-contsection {
      font-size: 12px;
      margin-top: 80px;
      width: 100%;
      height: 100%;
    }
  }
}

// services ka part
.homeservice-innerwrap {
  position: relative;
  padding: 60px 90px 40px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  .sub-heading {
    font-size: 28px;
    font-family: "Merriweather", serif;
    color: #000;
  }

  .homeservice-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      // width: calc(50% - 10px);
      position: relative;

      .individualhome-servicelist {
        width: 98%;
        margin-top: 15px;
        position: relative;

        .homeservice-imgsect {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .homeservice-contsect {
          position: absolute;
          top: 77%;
          left: 35%;
          transform: translate(-50%, -50%);
          z-index: 2;
          text-align: left !important;
          color: #f9f9f9;

          h5 {
            font-size: 18px;
            color: #fff;
            font-family: "Merriweather", serif;
            font-weight: 400;
            max-width: 230px;
            line-height: 24px;
          }

          p {
            color: #fff;
            font-size: 12px;
            line-height: 20px;
            max-width: 305px;
          }
        }

        .perform-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background-color: rgba(177, 130, 3, 0.8);
          transition: height 0.6s ease-out;
        }

        &:hover {
          .homeservice-contsect {
            .homeservice-contsect-inner {
              transform: translateY(-75%);
              transition: transform 0.5s ease-out, color 0.5s ease-out;

              h5,
              p {
                color: #000;
              }
            }
          }

          .perform-overlay {
            height: 100%;
          }

          .arrowbtn-wrap {
            opacity: 1;
          }
        }

        .arrowbtn-wrap {
          position: absolute;
          z-index: 99;
          bottom: 8px;
          left: 30;
          opacity: 0;
          transition: opacity 0.3s ease-out;
          display: block;
        }

        @media (max-width: 767px) {
          width: 100%;
          height: auto;

          .homeservice-contsect {
            top: 60%;
          }

          .homeservice-innerwrap {
            padding: 20px;
          }

          &:hover {
            .homeservice-contsect {
              .homeservice-contsect-inner {
                transform: translateY(-40%);
                transition: transform 0.3s ease-out, color 0.3s ease-out;

                h5,
                p {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}
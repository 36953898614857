footer {
  background-color: black;
  color: white;
  cursor: pointer;

  .footer-top {
    .container {
      padding: 70px 0 50px;

      .row {
        flex-wrap: wrap;
        .col-lg-3 {
          flex: 0.6;
          width: 100%;

          ul.address1 {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding-bottom: 7px;

              a {
                color: white;
                font-size: 11px;
                font-family: "Open Sans", sans-serif;
                text-decoration: none;
                background: transparent;
              }
            }
          }
        }

        .col-md-3 {
          flex: 1;
          width: 100%;
          // margin-bottom: 20px;

          ul.social-icon {
            list-style: none;
            padding: 0;
            margin: 0;
            // margin-top: 20px;
            text-align: left;
            font-weight: 100;
            font-size: 11px;

            li {
              display: inline-block;
              margin-right: 40px;

              a {
                color: grey;
                font-size: 16px;
                transition: color 0.3s ease;
              }
            }
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding-bottom: 7px;

              i.fa {
                color: grey;
                margin-right: 5px;
              }

              a {
                color: white;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 70px 0 50px;
      max-width: 1000px;
      margin: 0 auto;
      width: 100%;
    }
  }
}
